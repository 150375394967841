.reat-time__wrap {
	border-bottom: 1px solid $dark-500;
	background-color: #0f121d;

	@include media(xl) {
		padding: 0px 32px;
	}

	.reat-time__title {
		text-align: center;
		padding-top: 90px;

		@include media(lg) {
			text-align: left;
			padding-top: 37px;
		}

		h2 {
			font-family: 'sf-ui-display-200';
			font-size: 48px;
			line-height: 57px;
			max-width: 510px;
			margin: 33px auto 20px;
			color: $white;

			@include media(lg) {
				font-size: 32px;
				line-height: 38px;
				margin: 22px 30px 18px 0;
				max-width: 260px;
			}

			span {
				animation: gradient 2s linear infinite;
				background: -webkit-linear-gradient(
					15deg,
					$gradient-1,
					$gradient-2,
					$gradient-1,
					$gradient-2
				);
				background-size: 300%;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				/*
				background: linear-gradient(
					269.26deg,
					$gradient-1 39.37%,
					$gradient-2 76.57%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				*/
				font-family: 'sf-ui-display-400';
			}
		}

		p {
			max-width: 800px;
			margin: 0 auto;

			@include media(lg) {
				font-size: 18px;
				padding: 1px 0 10px 0;
				margin: 0;
			}
		}
	}

	.reat-time__bullets {
		max-width: 1200px;
		margin: 110px auto 100px;
		display: flex;
		gap: 55px 90px;
		flex-wrap: wrap;

		@include media(lg) {
			margin: 68px auto 32px;
			gap: 22px 0;
		}

		.item {
			flex-grow: 1;
			width: calc(33% - 90px);

			@include media(lg) {
				width: 100%;
			}

			.item__title_wrap {
				@include media(lg) {
					display: flex;
				}

				h3 {
					font-family: 'sf-ui-display-300';
					font-size: 22px;
					line-height: 142%;
					color: $white;
					margin: 0;
				}

				img {
					@include media(lg) {
						margin-right: 11px;
					}
				}
			}

			p {
				font-size: 16px;
				line-height: 142%;
			}
		}
	}
}
