$white: #ffffff;
$black: #000000;

$primary-300: #dee1ff;
$primary-400: #aeacff;
$primary-500: #6f6ce7;
$primary-600: #5d65b1;
$primary-700: #4a529f;

$dark: #0f121d;
$dark-600: #25253a;
$dark-500: #33334c;
$dark-400: #42425f;
$dark-300: #33334a;
$dark-200: #5b5b76;
$dark-100: #767695;
$dark-50: #9797b6;

$gradient-1: #b3b1ff;
$gradient-2: #7572da;
