.plugin__wrap {
	border-bottom: 1px solid $dark-500;
	background-color: #0f121d;

	@include media(xl) {
		padding: 0 32px;
	}

	.plugin__title {
		text-align: center;
		padding-top: 78px;

		@include media(lg) {
			text-align: left;
			padding-top: 38px;
		}

		h2 {
			font-family: 'sf-ui-display-200';
			font-size: 48px;
			line-height: 57px;
			max-width: 720px;
			margin: 35px auto 20px;
			color: $white;

			@include media(lg) {
				font-size: 32px;
				line-height: 38px;
				margin: 27px 0 18px;
			}

			span {
				animation: gradient 2s linear infinite;
				background: -webkit-linear-gradient(
					15deg,
					$gradient-1,
					$gradient-2,
					$gradient-1,
					$gradient-2
				);
				background-size: 300%;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				/*
				background: linear-gradient(
					269.26deg,
					$gradient-1 80.12%,
					$gradient-2 99.71%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				*/
				font-family: 'sf-ui-display-400';
			}
		}

		p {
			max-width: 800px;
			margin: 0 auto;

			@include media(lg) {
				font-size: 18px;
				line-height: 142%;
				margin: 0 0;
			}
		}
	}

	.plugin-items {
		max-width: 1200px;
		margin: 78px auto 100px;
		display: flex;
		gap: 30px;
		flex-wrap: wrap;

		@include media(lg) {
			margin: 69px auto 65px;
		}

		.item {
			flex-grow: 1;
			width: calc(33% - 30px);

			@include media(lg) {
				width: 100%;
			}

			.img-wrap {
				background: $dark-600;
				border-radius: 12px;
				height: 180px;
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 847px;
				margin: auto;
				overflow: hidden;
				border:2px solid #51516e;

				@include media(lg){
					border:none;
				}

				img{
					transform: translateY(19px);

					@include media(lg){
						transform:unset;
						width: 283px;
						height: 113px;
					}
				}
			}

			.title {
				padding: 32px 10px 0 22px;
				justify-content: center;
				display: flex;
				max-width:332px;
				margin: auto;

				@include media(lg){
					justify-content: start;
					margin: unset;
				}

				@include media(xl) {
					padding: 29px 11px 0 0px;
				}

				.number,
				.seperator {
					font-family: 'roboto_monoregular';
					font-size: 20px;
				}

				.number {
					color: $primary-400;
				}

				.seperator {
					padding: 0 5px;
				}

				.text {
					font-family: 'sf-ui-display-300';
					font-size: 22px;
					line-height: 142%;
					color: $white;
				}
			}

			p {
				font-size: 16px;
				line-height: 142%;
				max-width:400px;
				margin: auto;
				margin-top: 12px;
				text-align: center;

				@include media(lg){
					text-align: left;
					margin: unset;
					margin-top: 12px;
					max-width:1000px;
				}

				@include media(xl) {
					padding-left: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}
