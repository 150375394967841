/* https://www.cufonfonts.com/font/sf-ui-display */

@font-face {
	font-family: 'sf-ui-display-100';
	src: url('../../assets/fonts/sf-ui-display-ultralight-58646b19bf205-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-ultralight-58646b19bf205-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-200';
	src: url('../../assets/fonts/sf-ui-display-thin-58646e9b26e8b-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-thin-58646e9b26e8b-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-300';
	src: url('../../assets/fonts/sf-ui-display-light-58646b33e0551-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-light-58646b33e0551-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-400';
	src: url('../../assets/fonts/sf-ui-display-medium-58646be638f96-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-medium-58646be638f96-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-500';
	src: url('../../assets/fonts/sf-ui-display-semibold-58646eddcae92-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-semibold-58646eddcae92-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-600';
	src: url('../../assets/fonts/sf-ui-display-bold-58646a511e3d9-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-bold-58646a511e3d9-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-700';
	src: url('../../assets/fonts/sf-ui-display-heavy-586470160b9e5-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-heavy-586470160b9e5-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'sf-ui-display-800';
	src: url('../../assets/fonts/sf-ui-display-black-58646a6b80d5a-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/sf-ui-display-black-58646a6b80d5a-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'roboto_monoregular';
	src: url('../../assets/fonts/robotomono-variablefont_wght-webfont.woff2')
			format('woff2'),
		url('../../assets/fonts/robotomono-variablefont_wght-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
