.footer {
	background: $primary-500;
	border-radius: 40px 40px 0px 0px;

	.footer-animation-start {
		margin-top: 100px;
		@include transition(margin-top ease-in 0.7s);

		&.footer-animation-end {
			margin-top: 0;
			@include transition(margin-top ease-in 0.7s);
		}
	}

	@include media(xl) {
		border-radius: 24px 24px 0px 0px;
	}

	.action {
		h2 {
			font-family: 'sf-ui-display-200';
			letter-spacing: 0.025em;
			text-align: center;
			font-size: 48px;
			line-height: 119.5%;
			padding-top: 85px;
			max-width: 600px;
			margin: 0 auto;
			color: $white;

			@include media(lg) {
				font-size: 38px;
				line-height: 119.5%;
				padding-top: 62px;
			}
		}
		a.get-started-btn {
			margin: 35px auto;
			border: 1px solid $white;
		}
	}

	.footer-inner {
		max-width: 1200px;
		padding-top: 80px;
		margin: auto;
		display: flex;
		border-bottom: 1px solid $primary-400;
		padding-bottom: 90px;

		@include media(xl) {
			padding: 47px 30px 50px;
			flex-wrap: wrap-reverse;
		}

		@include media(lg) {
			padding: 47px 30px 2px;
			padding-bottom: 50px;
			border: 0;
		}

		.widget {
			flex: 75% 1;
			@include media(lg) {
				flex: 100%;
			}

			&.links {
				display: flex;
				flex: 17% 1;

				@include media(lg) {
					display: block;
					margin-bottom: 41px;
					border-top: 1px solid $primary-400;
					padding-top: 32px;
				}
			}

			p {
				font-size: 16px;
				line-height: 142%;
				color: $primary-300;
				padding-top: 15px;
				margin: 0;
				max-width: 435px;
			}

			ul {
				margin: 0;
				padding: 0;
				flex: 50% 1;

				li {
					list-style: none;
					padding-bottom: 8px;

					a {
						font-size: 16px;
						line-height: 142%;
						color: $white;
						text-decoration: none;
						position: relative;

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							transform: scaleX(0);
							border-radius: 5px;
							height: 0.09em;
							bottom: 0;
							left: 0;
							background: $white;
							transform-origin: bottom right;
							transition: transform 0.25s ease-out;
						}
						&:hover::after {
							transform: scaleX(1);
							transform-origin: bottom left;
						}
					}
				}
			}

			@include media(lg) {
				&:not(.links) {
					border-bottom: 1px solid $primary-400;
					padding-bottom: 17px;
				}
			}
		}
	}

	.rights {
		padding-bottom: 85px;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		padding: 0px 35px 70px 35px;

		@include media(lg) {
			padding: 0 35px 5px;
			display: block;
		}

		@include media(lg) {
			padding: 0 30px 5px;
			flex-wrap: wrap-reverse;
			display: flex;
		}

		p {
			font-size: 16px;
			line-height: 142%;
			color: $primary-300;
			padding-top: 7px;

			@include media(lg) {
				width: 100%;

				&:first-child {
					margin-top: 0;
				}

				:not(:first-child) {
					margin-bottom: 0;
				}
			}
		}
	}

	&.stiky-footer {
		position: fixed;
		bottom: 0px;
		left: 0px;
		z-index: -1;
		width: 100%;

		.footer-inner {
			padding-bottom: 47px;
		}
	}
}
