.hero {
	background-color: #0f121d;
	position: relative;
    max-width: 1800px;
    margin: auto;
	
	.hero-bg {
		background-image: url('../../assets/img/hero-bg-full.webp');
		background-position: right 82px;
		background-repeat: no-repeat;
		background-size: 800px;
		padding-top: 82px;
		height: 700px;
		position: absolute;
		right: 0;
		width: 58%;
		transition: all 0.3s ease-in-out;

		/* Animation */
		animation: fadeInAndSlideFromLeft 0.8s ease-in-out forwards;
		opacity: 0;
		transform: translateX(50px);

		@include media(2xl) {
			background-size: 700px;
		}

		@include media(xl) {
			background-position: 8px 82px;
		}

		@include media(lg) {
			display: none;
		}
	}

	@include media(lg) {
		background-image: inherit;
		text-align: center;
		padding-top: 103px;
	}

	.hero__wrap {
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-flow: row wrap;
		height: 690px;
		align-items: center;
		margin-bottom: 80px;

		@include media(xl) {
			padding: 0 35px;
			margin-bottom: 0;
		}

		@include media(lg) {
			height: auto;
			flex-wrap: wrap;
			padding-top: 40px;
			position: relative;
			padding: 0 0;
		}

		.hero__content {
			flex: 45%;
			padding-top: 155px;

			@include media(lg) {
				flex: 100%;
				padding-top: 0;
			}

			.get-started-btn {
				@include media(lg) {
					max-width: calc(100% - 48px);
					width: calc(100% - 48px);
					margin: 0 24px;
				}
			}

			img {
				animation: fadeIn 1s ease-in-out;
				animation-fill-mode: forwards;
				opacity: 0;
				animation-delay: 0.1s;
			}

			h1.hore-title {
				color: $white;
				font-family: 'sf-ui-display-200';
				font-size: 48px;
				line-height: 57px;
				margin-bottom: 17px;
				margin-top: 21px;

				animation: fadeIn 1s ease-in-out;
				animation-fill-mode: forwards;
				opacity: 0;
				animation-delay: 0.2s;

				@include media(lg) {
					font-size: 32px;
					line-height: 38px;
					margin-top: 0px;
					margin-bottom: 10px;
				}

				span {
					animation: gradient 2s linear infinite;
					background: -webkit-linear-gradient(
						15deg,
						$gradient-1,
						$gradient-2,
						$gradient-1,
						$gradient-2
					);
					background-size: 300%;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;

					font-family: 'sf-ui-display-400';
				}
			}

			p {
				margin-top: 0;
				animation: fadeIn 1s ease-in-out;
				animation-fill-mode: forwards;
				opacity: 0;
				animation-delay: 0.3s;

				@include media(lg) {
					padding: 0px 15px;
					margin-bottom: 30px;
				}
			}

			.get-started-btn {
				animation: fadeIn 1s ease-in-out;
				animation-fill-mode: forwards;
				opacity: 0;
				animation-delay: 0.4s;

				@include media(lg) {
					max-width: 400px;
					margin: auto;
				}
			}
		}

		.hero__image {
			flex: 55%;

			@include media(lg) {
				flex: 100%;
			}

			img.mobile-only {
				display: none;
				margin-left: 62px;

				@include media(xl) {
					margin: auto;
				}

				@include media(lg) {
					display: block;
				}
			}
		}
	}

	.powered-by-cycode {
		height: 112px;
		border-bottom: 1px solid $dark-500;
		justify-content: center;
		display: flex;
		align-items: center;

		animation: fadeIn 1s ease-in-out;
		animation-fill-mode: forwards;
		opacity: 0;
		animation-delay: 0.7s;

		@include media(lg) {
			padding-top: 65px;
			padding-top: 20px;
		}
	}

	.animation {
		h1 {
			color: #ffffff;
			font-family: 'sf-ui-display-200';
			font-size: 48px;
			line-height: 49px;
			margin-bottom: 17px;
			margin-top: 7px;
			max-width: 440px;
			height: 101px;

			@include media(lg) {
				font-size: 32px;
				line-height: 38px;
				margin-top: 0px;
				margin-bottom: 10px;
			}

			> span:nth-child(3) {
				padding-right: 57px;
				font-family: 'sf-ui-display-400';
			}
		}
	}
}

.gradient-text {
}

@keyframes gradient {
	from {
		background-position: 100% 0%;
	}
	to {
		background-position: 0% 0%;
	}
}
