@mixin transition($x...) {
	-webkit-transition: $x;
	-moz-transition: $x;
	-ms-transition: $x;
	-o-transition: $x;
	transition: $x;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $color;
		-moz-box-shadow: inset $top $left $blur $color;
		box-shadow: inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

/* media query sizes */
$sizes: (
	xs: 480px,
	sm: 640px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	2xl: 1400px,
	3xl: 1920px,
);

/* media query func. */
@mixin media($bp) {
	@each $bps, $size in $sizes {
		@if $bp == $bps {
			@media screen and (max-width: $size) {
				@content;
			}
		}
	}
}
