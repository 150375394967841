:root {
	font-size: 22px;
}

html,
body {
	overflow-x: hidden;
	width: 100%;
}

body {
	position: relative;
	background-color: $dark;
	margin: 0;
	padding: 0;
	color: $dark-50;
	font-family: 'sf-ui-display-300';
}

img {
	max-width: 100%;
}

.get-started-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $white;
	border-radius: 8px;
	max-width: 161px;
	height: 51px;
	font-size: 18px;
	line-height: 21px;
	text-decoration: none;
	font-family: 'sf-ui-display-300';
	color: $dark-600;
	margin-top: 40px;
	@include transition(all 0.3s);

	@include media(lg) {
		max-width: 88%;
	}

	svg {
		width: 26px;
		padding-right: 10px;
		path {
			@include transition(all 0.3s);
			fill: $black;
		}
	}

	span {
		padding-top: 2px;
	}

	&:hover {
		svg path {
			fill: $white;
		}

		background: $primary-500;
		color: $white;
	}

	&:active,
	&:focus {
		svg path {
			fill: $white;
		}
		background: $primary-700;
		color: $white;
	}
}

.hide-animation {
	opacity: 0;
}
.show-animation-1 {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: 0.1s;
}
.show-animation-2 {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}
.show-animation-3 {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: 0.3s;
}
.show-animation-4 {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}
.show-animation-5 {
	animation: fadeIn 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: 0.7s;
}

@keyframes fadeInAndSlideFromLeft {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInAndSlideFromRight {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInAndSlideFromTop {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
