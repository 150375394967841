.nav {
	position: fixed;
	width: 100%;
	top: 0;
	background: $dark;
	z-index: 2;

	/* Animation */
	animation: fadeInAndSlideFromTop 1s ease-in-out forwards;
	opacity: 0;
	transform: translateY(-20px);

	&.stiky-menu {
		border-bottom: 1px solid $dark-300;
	}

	@include media(xl) {
		padding: 0 25px;
		width: calc(100% - 50px);
	}

	.nav-bar__wrap {
		max-width: 1200px;
		margin: 0 auto;
		height: 80px;

		@include media(lg) {
			height: 62px;
		}

		.nav-bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			overflow: hidden;

			img {
				max-width: 100px;
				@include media(lg) {
					max-width: 82px;
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;

			&.mobile-only {
				display: none;
			}

			.mobile-menu-close-icon {
				display: none;
				position: absolute;
				right: 18px;
				top: 18px;
				cursor: pointer;
			}

			.mobile-menu-burger-icon {
				position: absolute;
				right: 18px;
				top: 18px;
				cursor: pointer;
			}

			li {
				margin-left: 12px;
				border-radius: 6px;
				cursor: pointer;
				@include transition(all 0.5s);

				&:hover {
					background: $dark-500;
				}

				&:focus,
				&:active {
					background: $dark-400;
				}

				a {
					font-family: 'sf-ui-display-300';
					font-size: 16px;
					color: $white;
					text-decoration: none;
				}

				&.support,&.doc{
					padding: 1px 12px;
				}

				&.login {
					border: 1px solid $dark-50;
					margin-left: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 1px 12px;
					gap: 10px;

					@include media(lg){
						justify-content: start;
					}

					a {
						display: flex;
						gap: 10px;
					}
				
				}

				&.get-started {
					background: $primary-500;
					margin-right: -102px;
					display: flex;
					align-items: center;
					padding-left: 12px;
					opacity: 0;

					transition-property: margin-right, opacity;
					transition-duration: 0.3s, 1.2s;
					transition-timing-function: cubic-bezier(
						0.07,
						0.11,
						0.25,
						0.81
					);

					a {
						font-size: 14px;
						line-height: 17px;
						padding: 7px 12px;
						padding-top: 10px;
					}

					&:hover {
						background: $primary-600;
					}

					&:focus,
					&:active {
						background: $primary-700;
					}

					&.show {
						margin-right: 0;
						opacity: 1;
					}
				}
			}
		}
	}

	&.mobile {
		.nav-bar__wrap .nav-bar ul {
			&:not(.mobile-only) {
				.mobile-menu-close-icon {
					display: block;
				}

				//#root > div.nav.mobile > div > div > ul.show > li:nth-child(2)

				&.show {
					right: 0;
					height: 1500px;

					li {
						&:nth-child(2) {
							border-top: 1px solid $dark-400;
						}
					}
				}

				@include transition(all 0.5s);
				z-index: 3;
				display: block;
				background: #0f121d;
				position: fixed;
				right: -100%;
				height: 100%;
				top: 0;
				width: 100%;
				padding-top: 59px;

				li {
					margin-left: 0;
					padding: 22px 15px 22px 20px;
					border-bottom: 1px solid $dark-400;
					border-radius: 0;

					&.login {
						border-top: 0;
						border-left: 0;
						border-right: 0;
						border-radius: 0;
						margin-left: 0;
					}

					&.get-started {
						background: transparent;
						border-radius: 0;
						margin-right: 0;
					}

					&:hover {
						background: $dark-500;
					}

					&:focus,
					&:active {
						background: $dark-400;
					}

					a {
						font-size: 20px;
						display: flex;
						align-items: center;
						gap: 10px;
						width: 100%;
					}
				}
			}
			&.mobile-only {
				display: block;

				.get-started {
					@include transition(all 0.3s);
					margin-top: -100px;
					margin-right: 29px;

					&.show {
						margin-top: -7px;
					}
				}
			}
		}
	}
}

.nav-fade-in {
	animation: fadeIn 1s;
	-webkit-animation: fadeIn 1s;
	-moz-animation: fadeIn 1s;
	-o-animation: fadeIn 1s;
	-ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		//margin-top: -50px;
	}
	100% {
		opacity: 1;
		//margin-top: 0;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
		//margin-top: -50px;
	}
	100% {
		opacity: 1;
		//margin-top: 0;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
		//margin-top: -50px;
	}
	100% {
		opacity: 1;
		//margin-top: 0;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
		//margin-top: -50px;
	}
	100% {
		opacity: 1;
		//margin-top: 0;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
		//margin-top: -50px;
	}
	100% {
		opacity: 1;
		//margin-top: 0;
	}
}
