.faqs {
	background-color: #0f121d;
	@include media(xl) {
		padding: 0 32px;
	}

	.faqs__wrap {
		padding-bottom: 150px;

		@include media(xl) {
			padding-bottom: 65px;
		}

		.faqs__title {
			text-align: center;
			padding-top: 78px;
			margin-bottom: 95px;

			@include media(xl) {
				padding-top: 41px;
				margin-bottom: 39px;
			}

			h2 {
				font-family: 'sf-ui-display-400';
				font-size: 48px;
				line-height: 57px;
				max-width: 720px;
				margin: 8px auto 20px;
				background: linear-gradient(
					269.26deg,
					$gradient-1 80.12%,
					$gradient-2 99.71%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;

				@include media(xl) {
					font-size: 32px;
					line-height: 38px;
					text-align: left;
					margin: 0px auto 20px;
					padding-top: 62px;
				}
			}
		}

		.faq__items {
			max-width: 900px;
			margin: 0 auto;

			.faq {
				background: $dark-600;
				border-radius: 10px;
				@include box-shadow(0px, 2px, 2px, rgba(0, 0, 0, 0.1));
				margin-bottom: 12px;
				cursor: pointer;
				max-height: 80px;
				overflow: hidden;
				@include transition(max-height 400ms);

				@include media(xl) {
					margin-bottom: 16px;
					max-height: 100%;
				}

				.faq-question {
					position: relative;
					padding: 24px 20px 24px 32px;
					font-size: 22px;
					line-height: 142%;
					color: $white;
					font-family: 'sf-ui-display-300';
					display: flex;
					justify-content: space-between;

					@include media(xl) {
						padding: 27px 17px 24px 25px;
						font-size: 18px;
						line-height: 142%;
					}

					svg {
						@include transition(all 0.4s ease);
						path {
							@include transition(all 0.4s ease);
						}
					}
				}

				.faq-answer {
					padding: 0 32px;
					font-size: 18px;
					line-height: 142%;
					color: $primary-300;
					opacity: 0;

					overflow: hidden;
					padding: 0 74px 0 32px;
					@include transition(all ease-out 250ms);

					@include media(xl) {
						padding: 0 25px 0 25px;
						max-height: 0;
					}

					p {
						margin: 0;
					}

					p:not(:first-child) {
						margin: 30px 0 0 0;
					}

					a.link{
						color: #dee1ff;
						text-decoration: underline;
						transition: all .2s ease-in;

						&:hover{
							color: #edefff;
						}
					}
				}

				&:hover {
					background: $dark-500;
				}

				&.open {
					background: $dark-500;
					max-height: 300px;

					@include media(xl) {
						max-height: 700px;
					}

					.faq-question {
						svg {
							transform: rotate(-45deg);
							path {
								fill: $white;
							}
						}
					}

					.faq-answer {
						@include transition(all ease-out 250ms);
						padding-bottom: 23px;
						opacity: 1;
						max-height: 700px;
					}
				}
			}
		}
	}
}
